// api.js
import axios from 'axios';

const API_URL = 'https://api.gabanacoffeehouse.com/v1';

export const axiosAPI = axios.create({
    baseURL:API_URL,
    
    headers:{
        "Content-Type": "application/json"
    }
})

