import React ,{useState,useEffect} from 'react'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import { getOrderStatus } from '../../lib/consts/utils'
import { axiosAPI } from '../API/api'; // Import API function

const recentOrderData = [
	{
		id: '1',
		product_id: '4324',
		customer_id: '23143',
		customer_name: 'Amanuel Negussie',
		order_date: '2022-05-17T03:24:00',
		order_total: '435.50 BIRR',
		current_order_status: 'PLACED',
		shipment_address: 'IT'
	},
	{
		id: '7',
		product_id: '7453',
		customer_id: '96453',
		customer_name: 'Hirut Yiheyis',
		order_date: '2022-05-14T05:24:00',
		order_total: '96.35 BIRR',
		current_order_status: 'CONFIRMED',
		shipment_address: 'Finance'
	},
	{
		id: '2',
		product_id: '5434',
		customer_id: '65345',
		customer_name: 'Dalia Mohammednur',
		order_date: '2022-05-17T07:14:00',
		order_total: '836.44 BIRR',
		current_order_status: 'SHIPPED',
		shipment_address: 'Export'
	},
	{
		id: '3',
		product_id: '9854',
		customer_id: '87832',
		customer_name: 'Bisrat Meseret',
		order_date: '2022-05-16T12:40:00',
		order_total: '334.50 BIRR',
		current_order_status: 'SHIPPED',
		shipment_address: 'Realestate'
	},
	{
		id: '4',
		product_id: '8763',
		customer_id: '09832',
		customer_name: 'Hanan Kedir',
		order_date: '2022-05-14T03:24:00',
		order_total: '876.00 BIRR',
		current_order_status: 'OUT_FOR_DELIVERY',
		shipment_address: 'CEO Office'
	},
	{
		id: '5',
		product_id: '5627',
		customer_id: '97632',
		customer_name: 'Medina Jemal',
		order_date: '2022-05-14T05:24:00',
		order_total: '96.35 BIRR',
		current_order_status: 'DELIVERED',
		shipment_address: 'HR'
	}
]

export default function RecentOrders() {

	     //for pagination
		 const [currentPage, setCurrentPage] = useState(1);
		 const [totalPages, setTotalPages] = useState(1);
		 const [hasMorePages, setHasMorePages] = useState(true);
		 const [data, setData] = useState([]);
		 const [pagination, setPagination] = useState({
		   pageIndex: 1,
		   pageSize: 10, //customize the default page size
		 });
		 
		 useEffect(() => {
		   console.log("the pagination state changed!")
		   console.log(pagination.pageIndex)
		   console.log(pagination.pageSize)
		   setCurrentPage(pagination.pageIndex)
		   //do something when the pagination state changes
		 }, [pagination.pageIndex, pagination.pageSize]);

	

	useEffect(() => {
    
		// Function to fetch data from the API
		const fetchDataFromAPI = async () => {
		  try {
			const token = (JSON.parse(localStorage.getItem('user')))
			const response = await axiosAPI.get(
			    `/transactions/bulk?status=COMPLETED&role=ALL&page=${currentPage}`,{
				headers: {
				  Authorization: `Bearer ${token.data.accessToken}` 
				}
			  }
			) ;  
			console.log("this is the response",response);// Replace with your API endpoint
			// if (!response.ok) {
			//     throw new Error(`HTTP error! Status: ${response.status}`); 
			//   }
			const result = response.data;
			console.log(result);
			console.log("this is the docs",result.data);
			const fullData = []
  
			result.data.transaction.docs.map((obj) => {
			  const newData = {};
  
			  newData.id = obj.userId.firstName + " " + (obj.userId.lastName || '');
			  newData.type = obj.customerType.type;
			  newData.role = obj.customerType.role;
			  newData.totalPrice = obj.totalPrice;
			  newData.createdAt = obj.createdAt;
			  newData.transactionType =obj.transactionType;
  
			  let product = '';
			  obj.orders.map((order) => 
				product += order.product.name + ' x ' + order.quantity + '. '
			  )
			  newData.product = product;
  
			  return fullData.push(newData)
			  
			})
  
			setData(fullData)
  console.log("this is the full data ",fullData)
			console.log("and this is the DATA",data)
			//setData(result.data.transaction.docs);
			// debugger
			//console.log("this is the ID",data[0].id)
		  } catch (error) {
			
			// debugger
			console.error('Error fetching data:', error);
		  }
		};
	
		// Call the fetchData function 
		fetchDataFromAPI();
	  },[currentPage]);
  

	return (
		<div className="bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1">
			<strong className="text-gray-700 font-medium">Recent Orders</strong>
			<div className="border-x border-gray-200 rounded-sm mt-3">
				<table className="w-full text-gray-700">
					<thead>
						<tr>
							<th>Name</th>
							<th>orders</th>
							<th>Role</th>
							<th>Type</th>
							<th>Total Price</th>
							<th>Paid With</th>
							<th>Date</th>
						</tr>
					</thead>
					<tbody>
					{data
         //   .slice()
          //  .reverse() // Reverse the entire array
          //  .slice(0, 10) // Limit to the first 10 items
            .map((data) => (
							<tr key={data}>
								<td>
									{data.id}
								</td>
								<td>
									<Link to={`/layout/Report`}>{data.product}</Link>
								</td>
								<td>
									{data.role}
								</td>
								<td>{data.type}</td>
								<td>{Math.round(data.totalPrice)}</td>
								<td
                  className={`${
                    data.transactionType === 'CASH'
                      ? 'bg-green-200' // Green background for CASH
                      : 'bg-blue-400' // Blue background for TELEBIRR
                  }`}
                >
                  {data.transactionType}
                </td>
								<td>{format(new Date(data.createdAt), 'dd MMM yyyy')}</td>
								
								
								{/* <td>{getOrderStatus(data.id)}</td> */}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}