import React, { useEffect, useState } from "react";
import { FcBullish } from "react-icons/fc";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { HiOutlineLogout } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

// Import sidebar links
import {
  DASHBOARD_SIDEBAR_BOTTOM_LINKS,
  DASHBOARD_SIDEBAR_LINKS,
} from "../../lib/consts/navigation";

const linkClasses =
  "flex items-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base";

export default function Sidebar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    // Retrieve user role from local storage
    const authRole = localStorage.getItem("authRole");
    setUserRole(authRole);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/");
  };

  const renderSidebarLinks = () => {
    if (userRole === "SUPER_ADMIN") {
      return DASHBOARD_SIDEBAR_LINKS.map((item) => (
        <React.Fragment key={item.key}>
          {item.children ? (
            <>
              <SidebarLink item={item} />
              <div className="pl-4 gap-0.5">
                {item.children.map((childItem) => (
                  <SidebarLink key={childItem.key} item={childItem} isChild />
                ))}
              </div>
            </>
          ) : (
            <SidebarLink key={item.key} item={item} />
          )}
        </React.Fragment>
      ));
    } else if (userRole === "FINANCE") {
      return (
        <>
          <SidebarLink item={DASHBOARD_SIDEBAR_LINKS[0]} /> {/* Dashboard */}
          <SidebarLink item={DASHBOARD_SIDEBAR_LINKS.find(item => item.key === 'Report')} /> {/* Report */}
        </>
      );
    } else if (userRole === "BARISTA") {
      return (
        <>
          <SidebarLink item={DASHBOARD_SIDEBAR_LINKS[0]} /> {/* Dashboard */}
          <SidebarLink item={DASHBOARD_SIDEBAR_LINKS[1]} />
          <SidebarLink item={DASHBOARD_SIDEBAR_LINKS[2]} />
          <SidebarLink item={DASHBOARD_SIDEBAR_LINKS[3]} />
          <SidebarLink item={DASHBOARD_SIDEBAR_LINKS.find(item => item.key === 'Report')} /> {/* Report */}
        </>
      );
    }else if (userRole === "ADMIN") {
      return DASHBOARD_SIDEBAR_LINKS.map((item) => {
        if (item.key === "customers" && item.children) {
          return (
            <React.Fragment key={item.key}>
              <SidebarLink item={item} />
              <div className="pl-4 gap-0.5">
                {item.children.map((childItem) => {
                  // Exclude "Admins" from rendering
                  if (childItem.key === 'admin') {
                    return null;
                  }
                  return (
                    <SidebarLink
                      key={childItem.key}
                      item={childItem}
                      isChild
                    />
                  );
                })}
              </div>
            </React.Fragment>
          );
        } else if (item.key !== "customers") {
          return (
            <React.Fragment key={item.key}>
              {item.children ? (
                <>
                  <SidebarLink item={item} />
                  <div className="pl-4 gap-0.5">
                    {item.children.map((childItem) => (
                      <SidebarLink
                        key={childItem.key}
                        item={childItem}
                        isChild
                      />
                    ))}
                  </div>
                </>
              ) : (
                <SidebarLink key={item.key} item={item} />
              )}
            </React.Fragment>
          );
        }
        return null;
      });
    }
  };

  return (
    <div className="bg-neutral-900 w-60 p-3 flex flex-col text-blue">
      <div className="flex items-center gap-2 px-1 py-3">
        <FcBullish fontSize={24} />
        <span className="text-neutral-100 text-lg">Admin Dashboard</span>
      </div>
      <div className="flex-1 py-8 flex flex-col gap-0.5">
        {renderSidebarLinks()}
      </div>
      <div className="flex flex-col gap-0.5 pt-2 border-t border-neutral-700">
        {DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((item) => (
          <SidebarLink key={item.key} item={item} />
        ))}
      </div>
      <div className={classNames("text-red-500 cursor-pointer", linkClasses)}>
        <span className="text-xl">
          {" "}
          <HiOutlineLogout />{" "}
        </span>
        <button onClick={handleLogout}>Logout</button>
      </div>
    </div>
  );
}

function SidebarLink({ item, isChild }) {
  const { pathname } = useLocation();

  return (
    <Link
      to={item.path}
      className={classNames(
        pathname === item.path
          ? "bg-neutral-700 text-white"
          : "text-neutral-400",
        linkClasses,
        { "pl-4": isChild }
      )}
    >
      <span className="text-xl"> {item.icon}</span>
      {item.label}
    </Link>
  );
}
