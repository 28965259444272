import classNames from 'classnames'
import React, { useState, useEffect } from 'react';
import { axiosAPI } from '../API/api'; // Import API function
import { Link } from 'react-router-dom'

const popularProducts = [
	{
		id: '3432',
		product_name: 'Espresso',
		product_thumbnail: 'https://res.cloudinary.com/dqobuqymw/image/upload/v1707924012/Menu/Hot%20drink/Espresso_z3o3kg-three_hundred_adinsg.jpg',
		product_price: '40 BIRR',
		// product_stock: 341
	},
	{
		id: '7633',
		product_name: 'Iced Vanilla Latte',
		product_thumbnail: 'https://www.forkinthekitchen.com/wp-content/uploads/2022/07/220629.iced_.latte_.vanilla-8882.jpg',
		product_price: '160.00 BIRR',
		// product_stock: 24
	},
	{
		id: '6534',
		product_name: 'Macchiato',
		product_thumbnail: 'https://res.cloudinary.com/dqobuqymw/image/upload/v1710831164/Menu/uacvzjgbtiylfjn6jx73.webp',
		product_price: '60.00 BIRR',
		// product_stock: 56
	},
	{
		id: '9234',
		product_name: 'Capuccino',
		product_thumbnail: 'https://res.cloudinary.com/dqobuqymw/image/upload/v1707936393/Menu/Hot%20drink/cappuccino_togbfm-three_hundred_q0apir.webp',
		product_price: '110.00 BIRR',
		// product_stock: 98
	},
	{
		id: '4314',
		product_name: 'Spanish Latte',
		product_thumbnail: 'https://res.cloudinary.com/dqobuqymw/image/upload/v1707916116/Menu/Hot%20drink/Spanish-Coffee-051_jgmiuz-three_hundred_zumskb.webp',
		product_price: '110 BIRR',
		// product_stock: 0
	},
	{
		id: '4342',
		product_name: 'Lemon Tea',
		product_thumbnail: 'https://feedgrump.com/wp-content/uploads/2023/04/Hong-kong-lemon-tea-feature-500x500.jpg',
		product_price: '70 BIRR',
		// product_stock: 453
	}
]

function PopularProducts() {

	const [totalSales,setTotalSales] = useState(null)

	useEffect(()=>{

		const fetchDataFromAPI = async () => {
			try {
			  const response = await axiosAPI.get(
				`/aggregates/top-products`,{
					headers: {
					  Authorization: `Bearer ${localStorage.getItem('authToken')}` 
					}
				  }
			  ) ; 
			  console.log("this is the response from popular product",response);// Replace with your API endpoint 
			  const result = response.data;
	
			  console.log("The result from popular product: ", result);
			  //console.log("result count",result.data.aggregate.find((user)=> user._id==="CUSTOMER").count)
			setTotalSales(result.data.aggregate.flatMap((transaction)=>transaction.product))
			  // debugger
			} catch (error) {
			  // debugger
			  console.error('Error fetching data:', error);
			}
		  };
		  fetchDataFromAPI()

	},[])

	return (
		
		
		<div className="w-[20rem] bg-white p-4 rounded-sm border border-gray-200">
			{totalSales && (
				<>
				<strong className="text-gray-700 font-medium">Popular Products</strong>
			<div className="mt-4 flex flex-col gap-3">
				{totalSales.map((product) => (
					<Link
						key={product._id}
						to={`/product/${product._id}`}
						className="flex items-start hover:no-underline"
					>
						<div className="w-10 h-10 min-w-[2.5rem] bg-gray-200 rounded-sm">
							<img
								className="w-full h-full object-cover rounded-sm"
								src={product.image}
								alt={product.name}
							/>
						</div>
						<div className="ml-4 flex-1">
							<p className="text-sm text-gray-800">{product.name}</p>
							{/* <span
								className={classNames(
									product.product_stock === 0
										? 'text-red-500'
										: product.product_stock > 50
										? 'text-green-500'
										: 'text-orange-500',
									'text-xs font-medium'
								)}
							>
								{product.product_stock === 0 ? 'Out of Stock' : product.product_stock + ' in Stock'}
							</span> */}
						</div>
						<div className="text-xs text-gray-400 pl-1.5">{product.unitPrice} Birr</div>
					</Link>
				))}
			</div>
			</>
			)}
			
		</div>
	)
}

export default PopularProducts