import React, { useCallback, useMemo, useState , useEffect} from 'react';
import { MaterialReactTable } from 'material-react-table';
import { axiosAPI } from '../API/api'; // Import API function
// import axios from 'axios';



import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
 // MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
// import { usStates } from './makeData';
// import { data, states } from '../Products/makeData';


// Configure Cloudinary with your Cloudinary credentials
// cloudinary.config({
//   cloud_name: 'dqobuqymw',
//   api_key: '593372374249145',
//   api_secret: 'TLly48U4F1A4yBSlJFFQqdH6-KY',
// });


const Example = () => {

  const [cloudName] = useState("dqobuqymw");
  // Replace with your own upload preset
  const [uploadPreset] = useState("zydzkbyo");


  const [uwConfig] = useState({
    cloudName,
    uploadPreset
  });

  const [image,setImage]= useState("")


  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(true);

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10, //customize the default page size
  });
  
  useEffect(() => {
    console.log("the pagination state changed!")
    console.log(pagination.pageIndex)
    console.log(pagination.pageSize)
    setCurrentPage(pagination.pageIndex + 1)
    //do something when the pagination state changes
  }, [pagination.pageIndex, pagination.pageSize]);
  

  const handleCreateNewRow = async (values) => {
    try { 

      console.log("this is the value",values);
      const userData = (JSON.parse(localStorage.getItem('user')))
      console.log("this is the token",userData)
      // Make a POST request to your API endpoint with the new data 
      delete values.beansCount
      const response = await axiosAPI.post('/auth/register-employee', {...values,role:"FINANCE" },{
        headers : {
          Authorization: `Bearer ${userData.data.token}` 
        
        }
        
      }); console.log("This is the response  ",response)
      // Assuming the API response contains the newly created row data
      const newRowData = response.data;
console.log(newRowData);

      // Update the local state with the new row data
      setTableData([...tableData, newRowData]);

      // Close the modal
      setCreateModalOpen(false);
    } catch (error) {
      console.error('Error creating new row:', error);
      // Handle error appropriately (e.g., show an error message) 
    }
  }; 

  // const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
  //   if (!Object.keys(validationErrors).length) {
  //     tableData[row.index] = values;
  //     //send/receive api updates here, then refetch or update local table data for re-render
  //     setTableData([...tableData]);
  //     exitEditingMode(); //required to exit editing mode and close modal
  //   }
  // };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    try {
// if(row.original.discount=== values.discount){
//   delete values.discount
// }

      // Make an API request to update the row data
      const response = await fetch(`https://api.gabanacoffeehouse.com/v1/users/update-employee`, {
        method: 'PUT', // Assuming you're using a PUT request to update data
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers if needed
          'Authorization': `Bearer ${getAuthToken()}` // Inherit authorization from parent
        },
        body: JSON.stringify({...values,_id:row.original._id }), // Assuming values contains updated row data
      });
      console.log("this is the original ",row.original)
  
      if (!response.ok) {
        throw new Error('Failed to update row data');
      }
  
      // Update local table data or refetch data if necessary
      // For demonstration, let's assume setTableData is a function to update table data
      setTableData(previousData => {
        const newData = [...previousData];
        newData[row.index] = values; // Update the specific row with new values
        return newData;
      });
  
      // Exit editing mode and close the modal
      exitEditingMode();
    } catch (error) {
      console.error('Error updating row data:', error);
      // Handle errors appropriately (e.g., show error message to the user)
    }
  };
  

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const getAuthToken = () => {
    // Retrieve the authentication token from local storage
    const authToken = localStorage.getItem('authToken');
    return authToken;
  };

// Set the authorization header in the parent request
axiosAPI.interceptors.request.use(config => {
  // Assuming you have a function to get the authorization token from somewhere
  const authToken = getAuthToken(); // Implement this function according to your authentication method
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  return config;
});


  const handleDeleteRow = useCallback(
    (row) => {
   
      console.log(row.original._id)
      axiosAPI.delete(`/users/${row.original._id}`)
        .then(response => {
          // Handle successful deletion
          alert ("you have deleted an Employee successfully!");
          console.log('employee deleted successfully:', response.data);
          
          // Remove the deleted row from tableData
          const updatedTableData = tableData.filter(item => item._id !== row.original._id);
          
          // Update the state with the modified tableData
          setTableData(updatedTableData);
        })
        .catch(error => {
          // Handle error
          console.error('Error deleting item:', error);
          // Optionally, display an error message to the user
        });
    },
    [tableData, setTableData],
  );
  
  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === 'email'
              ? validateEmail(event.target.value)
              : cell.column.id === 'age'
              ? validateAge(+event.target.value)
              : validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors],
  );

  

  // const YourComponent = () => {

  
  useEffect(() => {
    // Function to fetch data from the API
    const fetchDataFromAPI = async () => {
      try {
        const response = await axiosAPI.get(
          `/users/all?disabled=false&role=FINANCE&page=${currentPage}`
        ) ; 
        console.log(response);// Replace with your API endpoint 
        // if (!response.ok) {
        //     throw new Error(`HTTP error! Status: ${response.status}`); 
        //   }
        const result = response.data;
        console.log("result,",result);
        // console.log("This is the 1st user ",result.data.user.docs[0].firstName);
        setData(result.data.user.docs); //.filter(item => item.role === 'FINANCE'))
        setTotalPages(result.data.user.totalDocs);
        setHasMorePages(result.data.user.hasNextPage);
        console.log("this is the total pages",totalPages)
        // debugger
      } catch (error) {
        // debugger
        console.error('Error fetching data:', error);
      }
    };

    // Call the fetchData function 
    fetchDataFromAPI();
  }, [currentPage]);
  
  const handleLoadMore = (e) => {
    console.log("this is e",e)
    if (hasMorePages) {
      setCurrentPage(currentPage + 1);
    }
  };
  // }
  const columns = useMemo(
    () => [
      {
        accessorKey: 'firstName',
        header: 'First Name',
        enableColumnOrdering: true,
        enableEditing: true, //disable editing on this column 
        enableSorting: true,
        size: 80,
      },
      // {
      //   accessorKey: 'firstName',
      //   header: 'First Name',
      //   enableColumnOrdering: false,
      //   enableEditing: true, //disable editing on this column
      //   enableSorting: false,
      //   size: 80,
      //   editVariant: 'select',
      //   editSelectOptions: usStates,
      //   muiEditTextFieldProps: {
      //     select: true,
      //     error: !!validationErrors?.state,
      //     helperText: validationErrors?.state,
      //   }, 
      // },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
        enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      // {
      //   accessorKey: 'email',
      //   header: 'email',
      //   size: 140,
      //   //editVariant: 'file', // Change the edit variant to 'file'
      //   muiEditTextFieldProps:  ({ cell }) => (
      //     {
      //     // ...getCommonEditTextFieldProps(cell),
      //    type: 'file',
      //    value:undefined , // Specify the type as 'file'
      //     onChange: async (event) => {
      //       const file = event.target.files[0];
      //      //setValues({ ...values, image: file }); // Update the 'image' field with the selected file
      //      const url = "https://api.cloudinary.com/v1_1/dqobuqymw/image/upload";
        
           
      //      const formData = new FormData();
           
            
      //          formData.append("file", file);
      //         formData.append("upload_preset", "zydzkbyo");
           
      //          fetch(url, {
      //            method: "POST",
      //            body: formData
      //          })
      //            .then(async (response) => {
                
      //              const imageUrl= await response.json();
      //              console.log("this is the img url",imageUrl);
      //              setImage(imageUrl.secure_url)

      //            })
             
      //      ;
      //    },}
      //   //   InputLabelProps: {
      //   //     shrink: true,
      //   //   },
      //   // }
      //   )
      // },
      {
        accessorKey: 'password',
        header: 'Password',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'password',
        }),
      },
      {
        accessorKey: 'role',
        header: 'ROLE',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'email',
        }),
      },
      // {
      //   accessorKey: 'discount',
      //   header: 'Discount',
      //   size: 80,
      //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell),
      //     type: 'number',
      //   }),
      // },
      // {
      //   accessorKey: 'beansCount',
      //   header: 'Beans Count',
      //   size: 80,
      //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell),
      //     type: 'number',
      //   }),
      // }
      // {
      //   accessorKey: 'state',
      //   header: 'Image',
      //   muiTableBodyCellEditTextFieldProps: {
      //     select: true, //change to select for a dropdown
      //     children: states.map((state) => (
      //       <MenuItem key={state} value={state}>
      //         {state}
      //       </MenuItem>
      //     )),
      //   },
      // },
    ],
    [getCommonEditTextFieldProps],
  );


  return (
    <>
      <MaterialReactTable
      manualPagination
      rowCount={totalPages}
      state={{pagination}}
      onPaginationChange={setPagination}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns.map(column => {
          if (column.accessorKey === 'image') {
            return {
              ...column,
              Cell: ({ cell }) => {
                const imageUrl = cell.row.original.image;
             //   console.log("this is the image url",imageUrl)
                if (!imageUrl) {
                  // Render some placeholder image or message if no image link is available
                  return <div>No Image Available</div>;
                }
                // Render the image if a valid image link is available
                return <img src={imageUrl} alt="Thumbnail" style={{ maxWidth: '100px', maxHeight: '100px' }} />;
              }
            };
          }
          else if (column.accessorKey === 'password') { // Check for the "Password" column
            return {
                ...column,
                Cell: ({ cell }) => (
                    <div>********</div> // Placeholder for password column
                )
            };
        }
          return column;
        })}
        data={data}
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        onPageChange={handleLoadMore}
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button
            color="secondary"
            onClick={() => setCreateModalOpen(true)}
            variant="contained"
          >
            Add new Finance
          </Button>
        )}
      />
      <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
    </>
  );
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit }) => {

  const [publicId, setPublicId] = useState("");
  // Replace with your own cloud name
  const [cloudName] = useState("dqobuqymw");
  // Replace with your own upload preset
  const [uploadPreset] = useState("zydzkbyo");


  const [uwConfig] = useState({
    cloudName,
    uploadPreset,
  });

  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {}),
  );

  const handleSubmit = () => {
    //put your validation logic here
    console.log(values);

    onSubmit(values);
    onClose();
  };

  const CustomDropdownField = ({ value, onChange }) => (
    <TextField
      placeholder='FINANCE'
      value="FINANCE"
      onChange={onChange}
      fullWidth
      disabled
    >
      {/* Options for the dropdown */}
      {/* <MenuItem value="HOT_DRINKS">HOT_DRINKS</MenuItem>
      <MenuItem value="ICED_BREW">ICED_BREW</MenuItem>
      <MenuItem value="ICED_ESPRESSO">ICED_ESPRESSO</MenuItem>
      <MenuItem value="ICED_DRINKS">ICED_DRINKS</MenuItem>
      <MenuItem value="PASTRIES">PASTRIES</MenuItem>
      <MenuItem value="OTHER_DRINKS">OTHER_DRINKS</MenuItem> */}

      {/* Add more options as needed */}
    </TextField>
  );
  
  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Add a new Customer</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {columns.map((column, index) => (
               index === 4 ? (
                <CustomDropdownField
                  key={column.accessorKey}
                  value={values[column.accessorKey]}
                  onChange={(e) => setValues({ ...values, [column.accessorKey]: e.target.value })}
                />
              ) : 
    //           column.accessorKey === 'image' ? (
    //             <Box key={column.accessorKey}>
    //               {/* <input
    //                 accept="image/*"
    //                 id="contained-button-file"
    //                 multiple
    //                 type="file"
    //                 style={{ display: 'none' }}
    //                 onChange={(e) => setValues({ ...values, [column.accessorKey]: e.target.files[0] })}
    //               /> */}
    //               <label htmlFor="contained-button-file">
    //               <CloudinaryUploadWidget uwConfig={uwConfig} setPublicId={setValues} values={values} />
    //               </label>
    //               {values[column.accessorKey] && (
    //   <div>
    //     <p>Image uploaded successfully:</p>
    //     <img src={values[column.accessorKey]} alt="Uploaded" style={{ maxWidth: '100px', maxHeight: '100px' }} />
    //   </div>
    // )}
    //             </Box> 
                
    //           ) : 
    column.accessorKey === 'password' ? (
      <TextField
          key={column.accessorKey}
          label={column.header}
          name={column.accessorKey}
          placeholder='*******'
          type="password" // Set type to 'password'
          onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
      />
  ) :           
    (
                // Render regular text field for other columns
              <TextField
                key={column.accessorKey}
                label={column.header}
                name={column.accessorKey}
                onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                }  
              /> 
            )))}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Create New Finance
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
const validateAge = (age) => age >= 18 && age <= 50;

export default Example;
