

export const data = [
  {
    id: '2023-1-20',
    firstName: 'Abdella Munir',
    lastName: 'Macchiato',
    email: '#fr87513',
    age: 7,
    state: 'IT',
    price: ( '40 Birr')
  },
  {
    id: '2023-1-23',
    firstName: 'Hirut Yiheyis',
    lastName: 'Capuccino',
    email: '#fr83456',
    age: 9,
    state: 'Finance',
    price: ( '45 Birr')
  },
  {
    id: '2023-1-21',
    firstName: 'Bisrat Meseret',
    lastName: 'Iced Latte',
    email: '#fr87843',
    age: 5,
    state: 'Real Estate',
    price: ( '110 Birr')
  },
  {
    id: '2023-1-27',
    firstName: 'Medina Jemal',
    lastName: 'Ginger Tea',
    email: '#fr78952',
    age: 4,
    state: 'HR',
    price: ( '50 Birr')
  },
  {
    id: '2023-1-19',
    firstName: 'Amanauel Negussie',
    lastName: 'Coffee',
    email: '#fr87456',
    age: 6,
    state: 'IT',
    price: ( '40 Birr')
  },
  {
    id: '2023-1-24',
    firstName: 'Abenezer Girma',
    lastName: 'Capuccino Latte',
    email: '#fr87951',
    age: 3,
    state: 'IT',
    price: ( '110 Birr')
  },
  {
    id: '2023-1-24',
    firstName: 'Yared Wubalem',
    lastName: 'Tea',
    email: '#fr87579',
    age: 1,
    state: 'HR',
    price: ( '40 Birr')
  },
  {
    id: '2023-1-24',
    firstName: 'Hanan Kedir',
    lastName: 'Iced Latte',
    email: '#fr87782',
    age: 7,
    state: 'CEO Office',
    price: ( '110 Birr')
  },
];

//50 us states array
export const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  'Puerto Rico',
];