
import { BrowserRouter as Router,Routes , Route } from "react-router-dom";
import Layout from "./componenets/shared/Layout";
import Dashboard from "./componenets/Dashboard/Dashboard";
import Products from "./componenets/Products/ProductsMain";
import Transactions from "./componenets/Transactions/Transactions"
import RawProducts from "./componenets/RawProducts/RawProducts"
import Customers from "./componenets/Users/Customers"
import ExampleAPI from "./componenets/Products/ExampleAPI";
import Report from "./componenets/Report/Report"
import Login from "./Pages/Login";
import PageNotFound from "./Pages/404-page"
import Employees from "./componenets/Users/Employees"
import Guests from "./componenets/Users/Guests"
import Finance from "./componenets/Users/Finance"
import Barista from "./componenets/Users/Barista"
import Admins from "./componenets/Users/Admins"
import Categories from "./componenets/Categories/Categories"
import './index.css'


function App() {
  return (
    
      <Router>
         <Routes>
            <Route path='layout' element={<Layout />}>
            <Route path='dashboard' element={<Dashboard />} /> 
            <Route path='products' element={<Products />} />
            <Route path='exampleapi' element={<ExampleAPI />} />
            <Route path='Transactions' element={<Transactions />} />
            <Route path='rawProducts' element={<RawProducts />} />
            <Route path='customers' element={<Customers />} />
            <Route path='Report' element={<Report />} />
            <Route path='Employees' element={<Employees />} />
            <Route path='Guests' element={<Guests />} />
            <Route path='Finance' element={<Finance />} />
            <Route path='Barista' element={<Barista />} />
            <Route path='Admins' element={<Admins />} />
            <Route path='Categories' element={<Categories />} />
            
          </Route>
          <Route path='/' element={ <Login />}/>
          <Route path="*" element={<PageNotFound />} />

          </Routes>
    </Router>
    
  )
}

export default App;
