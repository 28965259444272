import React, { PureComponent } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

const data = [
  {
    name: 'Jan',
    uv: 0,
    pv: 0,
    amt: 0,
  },
  {
    name: 'Feb',
    uv: 550,
    pv: 400,
    amt: 550,
  },
  {
    name: 'Mar',
    uv: 500,
    pv: 300,
    amt: 500,
  },
  {
    name: 'Apr',
    uv: 0,
    pv: 0,
    amt: 0,
  },
  {
    name: 'May',
    uv: 0,
    pv: 0,
    amt: 0,
  },
  {
    name: 'June',
    uv: 0,
    pv: 0,
    amt: 0,
  },
];

export default class Example extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/composed-chart-in-responsive-container-pkqmy';

  render() {
    return (
      <div className="w-[20rem] h-[22rem] bg-white p-4 rounded-sm border border-gray-200 flex flex-col" style={{ width: '100%', height: 300 }}>
        <strong className="text-gray-700 font-medium">Transaction</strong>
        <div className="mt-3 w-full flex-1 text-xs">
        <ResponsiveContainer>
          <ComposedChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" scale="band" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" />
            <Bar dataKey="pv" barSize={20} fill="#413ea0" />
            <Line type="monotone" dataKey="uv" stroke="#ff7300" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      </div> 
    );
  }
}