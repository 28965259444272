import {
	HiOutlineViewGrid,
	HiOutlineCube,
	HiUserGroup  ,
	HiOutlineShoppingCart,
	HiOutlineUsers,
	HiOutlineDocumentText,
	//HiOutlineAnnotation,
	HiOutlineQuestionMarkCircle,
	HiOutlineCog
} from 'react-icons/hi'
import { BsPersonFillCheck ,BsPersonWalking } from "react-icons/bs";
import { GiTakeMyMoney } from "react-icons/gi";
import { FaHotTubPerson } from "react-icons/fa6";
import { GrUserAdmin } from "react-icons/gr";

export const DASHBOARD_SIDEBAR_LINKS = [
	{
		key: 'dashboard',
		label: 'Dashboard',
		path: '/layout/dashboard',
		icon: <HiOutlineViewGrid />
	},
	{
		key: 'products',
		label: 'Products',
		path: '/layout/products',
		icon: <HiOutlineCube />
	},
	{
		key: 'categories',
		label: 'Categories',
		path: '/layout/categories',
		icon: <HiOutlineCube />
	},
	// {
	// 	key: 'exampleapi',
	// 	label: 'API Fetch',
	// 	path: '/layout/exampleapi',
	// 	icon: <HiOutlineCube />
	// },
	// {
	// 	key: 'rawProducts',
	// 	label: 'Raw Products',
	// 	path: '/layout/rawProducts',
	// 	icon: <HiOutlineShoppingCart />
	// },
	{
		key: 'customers',
		label: 'Users',
		icon: <HiOutlineUsers />,
		children: [
			{
				key: 'customers',
				label: 'Customers',
				path: '/layout/customers',
				icon:<BsPersonFillCheck  />
			},
			{
				key: 'employees',
				label: 'Employees',
				path: '/layout/employees',
				icon: <HiUserGroup   />
			},
			{
				key: 'guests',
				label: 'Guests',
				path: '/layout/guests',
				icon:<BsPersonWalking />
			},
			{
				key: 'finance',
				label: 'Finance',
				path: '/layout/finance',
				icon:<GiTakeMyMoney />
			},
			{
				key: 'barista',
				label: 'Barista',
				path: '/layout/barista',
				icon:<FaHotTubPerson />
			},
			{
				key: 'admin',
				label: 'Admins',
				path: '/layout/admins',
				icon:<GrUserAdmin />
			}
		]
	},
	// {
	// 	key: 'transactions',
	// 	label: 'Reports',
	// 	path: '/Transactions',
	// 	icon: <HiOutlineDocumentText />
	// },
	{
		key: 'Report',
		label: 'Reports',
		path: '/layout/Report',
		icon: <HiOutlineDocumentText />
	},
	// {
	// 	key: 'login',
	// 	label: 'login',
	// 	path: '/login',
	// 	icon: <HiOutlineAnnotation />
	// }
]

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
	// {
	// 	key: 'settings',
	// 	label: 'Settings',
	// 	path: '/settings',
	// 	icon: <HiOutlineCog />
	// },
	// {
	// 	key: 'support',
	// 	label: 'Help & Support',
	// 	path: '/support',
	// 	icon: <HiOutlineQuestionMarkCircle />
	// }
]