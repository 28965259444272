
import Products from "./Products";
import React, { useState , useEffect} from 'react';
import { axiosAPI } from '../API/api'; // Import API function
function ProductsMain() {
    const [category,setCategory]=useState(null)

    useEffect(()=>{
        async function getCategories(){
         const response = await axiosAPI.get(
           `/category`,{
             headers: {
               Authorization: `Bearer ${localStorage.getItem('authToken')}` 
             }
           }
         ) ; 
         // console.log("response",response.data)
         const finalData= response.data.data.categories.flatMap((category)=> {return {text:category.name,value:category._id}})
         // console.log("final data",finalData)
       
         setCategory(finalData)
         console.log("OBJ",finalData.map((data)=> data.text))
         console.log("this is cat",response.data.data.categories)

       }
       getCategories()
     },[])

  return (
    <div>
        {category &&  <Products categoriesMain={category} categoriesName={category.map((data)=> data.text)} /> }
     
    </div>
  );
}

export default ProductsMain;
