import React, { useState, useEffect } from 'react';
import { IoBagHandle, IoPieChart, IoPeople, IoCart } from 'react-icons/io5'
import { axiosAPI } from '../API/api'; // Import API function
export default function DashboardStatsGrid() {
	const [data, setData] = useState(null);
	const [totalSales,setTotalSales] = useState(null)
	// const [statsData, setStatsData] = useState(null);
	// const [guestLength, setGuestLength] =useState(null)

	// const [currentPage, setCurrentPage] = useState(1);
	// const [totalPages, setTotalPages] = useState(1);
	// const [hasMorePages, setHasMorePages] = useState(true);
  
	// const [pagination, setPagination] = useState({
	//   pageIndex: 1,
	//   pageSize: 10, //customize the default page size
	// });

	const [users,setUsers]= useState(null)

	useEffect(()=>{
		const fetchDataFromAPI = async () => {
			try {
			  const response = await axiosAPI.get(
				`/aggregates/guests-count`,{
					headers: {
					  Authorization: `Bearer ${localStorage.getItem('authToken')}` 
					}
				  }
			  ) ; 
			  console.log("this is the response from guests",response);// Replace with your API endpoint 
			  const result = response.data;
	
			  console.log("The result from guests aggregate is: ", result);
			//  console.log("result count",result.data.aggregate.find((user)=> user._id==="CUSTOMER").count)
			setData(result.data.aggregate)
			  // debugger
			} catch (error) {
			  // debugger
			  console.error('Error fetching data:', error);
			}
		  };
		  fetchDataFromAPI()

	},[])
useEffect(()=>{


	const fetchDataFromAPI = async () => {
		try {
		  const response = await axiosAPI.get(
			`/aggregates/users-count`,{
				headers: {
				  Authorization: `Bearer ${localStorage.getItem('authToken')}` 
				}
			  }
		  ) ; 
		  console.log("this is the response from users",response);// Replace with your API endpoint 
		  const result = response.data;

		  console.log("The result from users aggregate is: ", result);
		  console.log("result count",result.data.aggregate.find((user)=> user._id==="CUSTOMER").count)
		setUsers(result.data.aggregate)
		  // debugger
		} catch (error) {
		  // debugger
		  console.error('Error fetching data:', error);
		}
	  };
	  fetchDataFromAPI()
},[])

	// useEffect(() => {
	// 	console.log("the pagination state changed!")
	// 	console.log(pagination.pageIndex)
	// 	console.log(pagination.pageSize)
	// 	setCurrentPage(pagination.pageIndex + 1)
	// 	//do something when the pagination state changes
	//   }, [pagination.pageIndex, pagination.pageSize]);

    // useEffect(() => {
	// 	// Function to fetch data from the API
	// 	const fetchDataFromAPI = async () => {
	// 	  try {
	// 		const response = await axiosAPI.get(
	// 		  `/aggregates/guests-count`
	// 		) ; 
	// 		console.log(response);// Replace with your API endpoint 
	// 		// if (!response.ok) {
	// 		//     throw new Error(`HTTP error! Status: ${response.status}`); 
	// 		//   }
	// 		const result = response.data;
	// 		console.log("result of guest api,",result);
	// 		//console.log("This is the 1st user ",result.data.guests.docs[0].firstName);
	// 		setData(result.data.aggregate);
	// 		console.log("and this is the guest data",data)
	// 		//console.log("This is the total number of guests",result.data.guests.totalDocs);
	// 		// setTotalPages(result.data.guests.totalDocs);
	// 		// setGuestLength(result.data.guests.totalDocs)
	// 		// setHasMorePages(result.data.guests.hasNextPage);
	// 		// debugger
	// 	  } catch (error) {
	// 		// debugger
	// 		console.error('Error fetching data:', error);
	// 	  }
	// 	};
	
	// 	// Call the fetchData function 
	// 	fetchDataFromAPI();
	//   }, []);

	useEffect(()=>{

		const fetchDataFromAPI = async () => {
			try {
			  const response = await axiosAPI.get(
				`/aggregates/transactions-cash`,{
					headers: {
					  Authorization: `Bearer ${localStorage.getItem('authToken')}` 
					}
				  }
			  ) ; 
			  console.log("this is the response from total sales",response);// Replace with your API endpoint 
			  const result = response.data;
	
			  console.log("The result from total sales aggregate is: ", result);
			  //console.log("result count",result.data.aggregate.find((user)=> user._id==="CUSTOMER").count)
			setTotalSales(result.data.aggregate)
			  // debugger
			} catch (error) {
			  // debugger
			  console.error('Error fetching data:', error);
			}
		  };
		  fetchDataFromAPI()

	},[])

	return (
		<div className="flex gap-4 w-full">
			 {data && (
			 <BoxWrapper>
			 <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
				 <IoBagHandle className="text-2xl text-white" />
			 </div>
			 <div className="pl-4">
				 <span className="text-sm text-gray-500 font-light">Total Sales</span>
				 <div className="flex items-center">
					 <strong className="text-xl text-gray-700 font-semibold">{totalSales ? Math.floor(totalSales.find((sales) => sales).totalAmount) : 0} Birr</strong>
					 {/* <span className="text-sm text-green-500 pl-2">+343</span> */}
				 </div>
			 </div>
		 </BoxWrapper>
		  ) }
			<BoxWrapper>
				<div className="rounded-full h-12 w-12 flex items-center justify-center bg-orange-600">
					<IoPieChart className="text-2xl text-white" />
				</div>
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Total Guests</span>
					<div className="flex items-center">
						<strong className="text-xl text-gray-700 font-semibold">{data}</strong>
						{/* <span className="text-sm text-green-500 pl-2">-34</span> */}
					</div>
				</div>
			</BoxWrapper>
			<BoxWrapper>
				<div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-400">
					<IoPeople className="text-2xl text-white" />
				</div>
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Total Customers</span>
					<div className="flex items-center">
						<strong className="text-xl text-gray-700 font-semibold">{users ? users.find((user)=> user._id==="CUSTOMER").totalUsers:0}</strong>
						{/* <span className="text-sm text-red-500 pl-2">-30</span> */}
					</div>
				</div>
			</BoxWrapper>
			<BoxWrapper>
				<div className="rounded-full h-12 w-12 flex items-center justify-center bg-green-600">
					<IoCart className="text-2xl text-white" />
				</div>
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Total Employees</span>
					<div className="flex items-center">
						<strong className="text-xl text-gray-700 font-semibold">{users ? users.find((user)=> user._id==="EMPLOYEE").totalUsers:0}</strong>
						{/* <span className="text-sm text-red-500 pl-2">-43</span> */}
					</div>
				</div>
			</BoxWrapper>
		</div>
	)
}

function BoxWrapper({ children }) {
	return <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center">{children}</div>
}