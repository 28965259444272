import { responsive } from '@cloudinary/react';
import { axiosAPI } from '../API/api'; // Import API function

export const fakeData= [
  {
    id: '9s41rp',
    firstName: 'Kelvin',
    lastName: 'Langosh',
    email: 'Jerod14@hotmail.com',
    state: 'Ohio',
  },
  {
    id: '08m6rx',
    firstName: 'Molly',
    lastName: 'Purdy',
    email: 'Hugh.Dach79@hotmail.com',
    state: 'Rhode Island',
  },
  {
    id: '5ymtrc',
    firstName: 'Henry',
    lastName: 'Lynch',
    email: 'Camden.Macejkovic@yahoo.com',
    state: 'California',
  },
  {
    id: 'ek5b97',
    firstName: 'Glenda',
    lastName: 'Douglas',
    email: 'Eric0@yahoo.com',
    state: 'Montana',
  },
  {
    id: 'xxtydd',
    firstName: 'Leone',
    lastName: 'Williamson',
    email: 'Ericka_Mueller52@yahoo.com',
    state: 'Colorado',
  },
  {
    id: 'wzxj9m',
    firstName: 'Mckenna',
    lastName: 'Friesen',
    email: 'Veda_Feeney@yahoo.com',
    state: 'New York',
  },
  {
    id: '21dwtz',
    firstName: 'Wyman',
    lastName: 'Jast',
    email: 'Melvin.Pacocha@yahoo.com',
    state: 'Montana',
  },
  {
    id: 'o8oe4k',
    firstName: 'Janick',
    lastName: 'Willms',
    email: 'Delfina12@gmail.com',
    state: 'Nebraska',
  },
];

//50 us states array
export const usStates = [
  'Hot Drinks',
  'Iced Brew',
  'Iced Espresso',
  'Iced Drinks',
  'Pastries',
  'Other Drinks',
  'Fasting Menu'
];

export async function getCategories(){
  const response = await axiosAPI.get(
    `/category`,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}` 
      }
    }
  ) ; 
  console.log("response",response.data)
  const finalData= response.data.data.categories.flatMap((category)=> category.name + " - " +category._id)
  console.log("final data",finalData)
  return finalData;
}