import React from 'react'
import DashBoardStatsGrid from '../Dashboard/DashBoardStatsGrid'
import TransactionChart from '../Dashboard/TransactionChart'
import BuyerProfileChart from '../Dashboard/BuyerProfileChart'
import RecentOrders from '../Dashboard/RecenetOrders'
import PopularProducts from '../Dashboard/PopularProducts'

export default function Dashboard() {
  return (
    <div className='flex flex-col gap-4'> 
 <DashBoardStatsGrid /> 
 <div className="flex flex-row gap-4 w-full">
				<TransactionChart />
				<BuyerProfileChart />
			</div>
      <div className="flex flex-row gap-4 w-full">
				<RecentOrders />
        <PopularProducts />
        </div> 
      </div>
  )
}
