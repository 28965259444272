

export const data = [
    {
      id: '1',
      firstName: 'Milk',
      lastName: 'a concentrated form of coffee brewed with high pressure',
      email: '40 BIRR',
      age: 10,
      state: '2023-1-24',
    },
    {
      id: '2',
      firstName: 'Egg',
      lastName: 'espresso coffee drink, topped with a small amount of foamed or steamed milk to allow the taste of the espresso to still shine through.',
      email: '45 BIRR',
      age: 5,
      state: '2023-1-26',
    },
    {
      id: '3',
      firstName: 'Sugar',
      lastName: ' a chilled coffee beverage thats made by mixing espresso with chilled milk, simple syrup, and ice cubes.',
      email: '110 BIRR',
      age: 10,
      state: '2023-1-27',
    },
    {
      id: '4',
      firstName: 'Lemon',
      lastName: 'made by layering espresso and foamed milk in a glass of ice.',
      email: '110 BIRR',
      age: 10,
      state: '2023-1-28',
    },
    {
      id: '5',
      firstName: 'Tea herb',
      lastName: 'An aromatic beverage prepared by pouring hot or boiling water over cured or fresh leaves of Camellia sinensis',
      email: '40 BIRR',
      age: 5,
      state: '2023-1-29',
    },
    {
      id: '6',
      firstName: 'Coffee package',
      lastName: 'a beverage brewed from roasted coffee beans.',
      email: '45 BIRR',
      age: 10,
      state: '2023-1-30',
    },
    {
      id: '7',
      firstName: 'Chocolate',
      lastName: ' made by preparing tea decoction then adding lemon juice',
      email: '50 BIRR',
      age: 5,
      state: '2023-1-31',
    },
    {
      id: '8',
      firstName: 'Ginger',
      lastName: 'a herbal beverage that is made from ginger root.',
      email: '50 BIRR',
      age: 5,
      state: '2023-1-31',
    },
  ];
  
  //50 us states array
  export const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
    'Puerto Rico',
  ];