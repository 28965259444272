import React, { useCallback, useMemo, useState , useEffect} from 'react';
import { MaterialReactTable } from 'material-react-table';
import { axiosAPI } from '../API/api'; // Import API function
// import axios from 'axios';
import CloudinaryUploadWidget from "./CloudinaryUploadWidget";


import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { getCategories, usStates } from './makeData';
// import { data, states } from '../Products/makeData';


// Configure Cloudinary with your Cloudinary credentials
// cloudinary.config({
//   cloud_name: 'dqobuqymw',
//   api_key: '593372374249145',
//   api_secret: 'TLly48U4F1A4yBSlJFFQqdH6-KY',
// });

const Example = ({categoriesMain,categoriesName}) => {

  const [cloudName] = useState("dqobuqymw");
  // Replace with your own upload preset
  const [uploadPreset] = useState("zydzkbyo");


  const [uwConfig] = useState({
    cloudName,
    uploadPreset
  });

  const [image,setImage]= useState("")


  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [data, setData] = useState([]);


  const handleCreateNewRow = async (values) => {
    try { 

      const finalValues= {
  
        "image": values.image,
        "unitPrice": values.unitPrice,
        "hotness": values.hotness,
        "name": values.name,
        "discount": values.discount,
        "categoryRef": values["categoryRef.name"],
      
    }

      console.log("this is the value(create)",values);
      const userData = (JSON.parse(localStorage.getItem('user')))
      console.log("this is the token",userData)
      // Make a POST request to your API endpoint with the new data
      const response = await axiosAPI.post('/products/register', {...finalValues, postedBy: userData.data.user.id},{
        headers : {
          Authorization: `Bearer ${userData.data.token}` 
        
        }
        
      }); console.log("This is the response  ",response)
      // Assuming the API response contains the newly created row data
      const newRowData = response.data;
console.log(newRowData);

      // Update the local state with the new row data
      setTableData([...tableData, newRowData]);

      // Close the modal
      setCreateModalOpen(false);
    } catch (error) {
      console.error('Error creating new row:', error);
      // Handle error appropriately (e.g., show an error message) 
    }
  }; 

  // const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
  //   if (!Object.keys(validationErrors).length) {
  //     tableData[row.index] = values;
  //     //send/receive api updates here, then refetch or update local table data for re-render
  //     setTableData([...tableData]);
  //     exitEditingMode(); //required to exit editing mode and close modal
  //   }
  // };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    try {
      let finalValues= null;
      

if(row.original.discount=== values.discount){
  delete values.discount
}
console.log("final values",categoriesMain.find((single)=> single.text=== values.categoryRef))
  const categoryRef=  categoriesMain.find((single)=> single.text=== values.categoryRef).value
  if (image !== ""){
    finalValues={...values,categoryRef,image}

  }else {
    finalValues={...values,categoryRef}
  }
  console.log("this is the values",values)

   //   Make an API request to update the row data
      const response = await fetch(`https://api.gabanacoffeehouse.com/v1/products/${row.original._id}`, {
        method: 'PUT', // Assuming you're using a PUT request to update data
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers if needed
          'Authorization': `Bearer ${getAuthToken()}` // Inherit authorization from parent
        },
        body: JSON.stringify(finalValues), // Assuming values contains updated row data
      });
      console.log("this is the original ",row.original)
  
      if (!response.ok) {
        throw new Error('Failed to update row data');
      }
     
  

      // Update local table data or refetch data if necessary
      // For demonstration, let's assume setTableData is a function to update table data
      setTableData(previousData => {
        const newData = [...previousData];
        newData[row.index] = values; // Update the specific row with new values
        return newData;
      });
  
      // Exit editing mode and close the modal
      exitEditingMode();
    } catch (error) {
      console.error('Error updating row data:', error);
      // Handle errors appropriately (e.g., show error message to the user)
    }
  };
  

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const getAuthToken = () => {
    // Retrieve the authentication token from local storage
    const authToken = localStorage.getItem('authToken');
    return authToken;
  };

// Set the authorization header in the parent request
axiosAPI.interceptors.request.use(config => {
  // Assuming you have a function to get the authorization token from somewhere
  const authToken = getAuthToken(); // Implement this function according to your authentication method
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  return config;
});


  const handleDeleteRow = useCallback(
    (row) => {
   
      console.log(row.original._id)
      axiosAPI.delete(`/products/${row.original._id}`)
        .then(response => {
          // Handle successful deletion
          alert ("you have deleted a product successfully!");
          console.log('Item deleted successfully:', response.data);
          
          // Remove the deleted row from tableData
          const updatedTableData = tableData.filter(item => item._id !== row.original._id);
          
          // Update the state with the modified tableData
          setTableData(updatedTableData);
        })
        .catch(error => {
          // Handle error
          console.error('Error deleting item:', error);
          // Optionally, display an error message to the user
        });
    },
    [tableData, setTableData],
  );
  
  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === 'email'
              ? validateEmail(event.target.value)
              : cell.column.id === 'age'
              ? validateAge(+event.target.value)
              : validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors],
  );

  

  // const YourComponent = () => {

  
  useEffect(() => {
    // Function to fetch data from the API
    const fetchDataFromAPI = async () => {
      try {
        const response = await axiosAPI.get(
          "/products"
        ) ; 
        console.log(response);// Replace with your API endpoint
        // if (!response.ok) {
        //     throw new Error(`HTTP error! Status: ${response.status}`); 
        //   }
        const result = response.data;
        console.log("result product,",result);
        console.log("This is the 1st products ",result.data.products[0]._id);
        setData(result.data.products);
        // debugger
      } catch (error) {
        // debugger
        console.error('Error fetching data:', error);
      }
    };

    // Call the fetchData function 
    fetchDataFromAPI();
  }, []);
  // }
  const columns = useMemo(
    () => [
      // {
      //   accessorKey: '_id',
      //   header: 'ID',
      //   enableColumnOrdering: false,
      //   enableEditing: false, //disable editing on this column 
      //   enableSorting: false,
      //   size: 80,
      // },
      {
        id:'categoryRef',
        accessorKey:'categoryRef.name',
        //accessorFn: (row) => `${row.categoryRef.name} - ${row.categoryRef._id}`,
        header: 'Product Category',
        enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
        editVariant: 'select',
        editSelectOptions: categoriesName,
        muiEditTextFieldProps: {
         select: true,
         error: !!validationErrors?.state,
         helperText: validationErrors?.state,
        }, 
      },
      {
        accessorKey: 'name',
        header: 'Product Name',
        enableColumnOrdering: false,
        enableEditing: true, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      // {
      //   accessorKey: 'hotness',
      //   header: 'Priority',
      //   size: 140,
      //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell),
      //   }),
      // },
      {
        accessorKey: 'image',
        header: 'Image',
        size: 140,
        //editVariant: 'file', // Change the edit variant to 'file'
        muiEditTextFieldProps:  ({ cell }) => (
          {
          // ...getCommonEditTextFieldProps(cell),
         type: 'file',
         value:undefined , // Specify the type as 'file'
          onChange: async (event) => {
            const file = event.target.files[0];
           //setValues({ ...values, image: file }); // Update the 'image' field with the selected file
           const url = "https://api.cloudinary.com/v1_1/dqobuqymw/image/upload";
        
           
           const formData = new FormData();
           
            
               formData.append("file", file);
              formData.append("upload_preset", "zydzkbyo");
           
               fetch(url, {
                 method: "POST",
                 body: formData
               })
                 .then(async (response) => {
                
                   const imageUrl= await response.json();
                   console.log("this is the img url",imageUrl);
                   setImage(imageUrl.secure_url)

                 })
             
           ;
         },}
        //   InputLabelProps: {
        //     shrink: true,
        //   },
        // }
        )
      },
      {
        accessorKey: 'unitPrice',
        header: 'Price',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'email',
        }),
      },
      // {
      //   accessorKey: 'postedBy',
      //   header: 'postedBy',
      //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell),
      //     type: 'email',
      //   }),
      // },
      // {
      //   accessorKey: 'discount',
      //   header: 'Discount',
      //   size: 80,
      //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell),
      //     type: 'number',
      //   }),
      // },
      {
        accessorKey: 'discount',
        header: 'Discount Price',
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'number',
        }),
      },
      {
        accessorKey: 'hotness',
        header: 'Priority',
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      // {
      //   accessorKey: 'state',
      //   header: 'Image',
      //   muiTableBodyCellEditTextFieldProps: {
      //     select: true, //change to select for a dropdown
      //     children: states.map((state) => (
      //       <MenuItem key={state} value={state}>
      //         {state}
      //       </MenuItem>
      //     )),
      //   },
      // },
    ],
    [getCommonEditTextFieldProps],
  );


  return (
    <>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns.map(column => {
          if (column.accessorKey === 'image') {
            return {
              ...column,
              Cell: ({ cell }) => {
                const imageUrl = cell.row.original.image;
             //   console.log("this is the image url",imageUrl)
                if (!imageUrl) {
                  // Render some placeholder image or message if no image link is available
                  return <div>No Image Available</div>;
                }
                // Render the image if a valid image link is available
                return <img src={imageUrl} alt="Thumbnail" style={{ maxWidth: '100px', maxHeight: '100px' }} />;
              }
            };
          }
          return column;
        })}
        data={data}
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button
            color="secondary"
            onClick={() => setCreateModalOpen(true)}
            variant="contained"
          >
            Add new Product 
          </Button>
        )}
      />
      <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
    </>
  );
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit }) => {

    const [success,setSuccess]=useState(false)
    const [category,setCategory]=useState([])


    useEffect(()=>{

      async function fetchcategories(){
        try {
         

  const response = await axiosAPI.get(
    `/category`,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}` 
      }
    }
  ) ; 
  console.log("this is the category",response)
  setCategory(response.data.data.categories)
  setSuccess(true)
          
        } catch (error) {
          setSuccess(false)
          throw new Error(error.message)
          
        }
  
      
} 
fetchcategories();
     },[])

  const [publicId, setPublicId] = useState("");
  // Replace with your own cloud name
  const [cloudName] = useState("dqobuqymw");
  // Replace with your own upload preset
  const [uploadPreset] = useState("zydzkbyo");


  const [uwConfig] = useState({
    cloudName,
    uploadPreset,
  });

  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {}),
  );

  const handleSubmit = () => {
    //put your validation logic here
    console.log(values);

    onSubmit(values);
    onClose();
  };

  const CustomDropdownField = ({ value, onChange }) => (
    <TextField
      select
      value={value}
      onChange={onChange}
      fullWidth
    >
      {/* Options for the dropdown */}
{success && (
  category.map((singleCategory) =>  <MenuItem key={singleCategory._id} value={singleCategory._id}>{singleCategory.name}</MenuItem> )
)}
      {/* <MenuItem value="HOT_DRINKS">HOT_DRINKS</MenuItem>
      <MenuItem value="ICED_BREW">ICED_BREW</MenuItem>
      <MenuItem value="ICED_ESPRESSO">ICED_ESPRESSO</MenuItem>
      <MenuItem value="ICED_DRINKS">ICED_DRINKS</MenuItem>
      <MenuItem value="PASTRIES">PASTRIES</MenuItem>
      <MenuItem value="OTHER_DRINKS">OTHER_DRINKS</MenuItem> */}

      {/* Add more options as needed */}
    </TextField>
  );
  
  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Add a new Product</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {columns.map((column, index) => (
               index === 0 ? (
                <CustomDropdownField
                  key={column.accessorKey}
                  value={values[column.accessorKey]}
                  onChange={(e) => setValues({ ...values, [column.accessorKey]: e.target.value })}
                />
              ) : 
              column.accessorKey === 'image' ? (
                <Box key={column.accessorKey}>
                  {/* <input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => setValues({ ...values, [column.accessorKey]: e.target.files[0] })}
                  /> */}
                  <label htmlFor="contained-button-file">
                  <CloudinaryUploadWidget uwConfig={uwConfig} setPublicId={setValues} values={values} />
                  </label>
                  {values[column.accessorKey] && (
      <div>
        <p>Image uploaded successfully:</p>
        <img src={values[column.accessorKey]} alt="Uploaded" style={{ maxWidth: '100px', maxHeight: '100px' }} />
      </div>
    )}
                </Box> 
                
              ) : 
              (
                // Render regular text field for other columns
              <TextField
                key={column.accessorKey}
                label={column.header}
                name={column.accessorKey}
                onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                }  
              /> 
            )))}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Create New Product
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
const validateAge = (age) => age >= 18 && age <= 50;

export default Example;
