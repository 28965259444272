import React, { useEffect, useState } from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts'

import { axiosAPI } from '../API/api'; // Import API function
// import axios from 'axios';




const RADIAN = Math.PI / 180
const COLORS = ['#00C49F', '#7f0ee3', '#0e6ae3']

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5
	const x = cx + radius * Math.cos(-midAngle * RADIAN)
	const y = cy + radius * Math.sin(-midAngle * RADIAN)

	return (
		<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	)
}

export default function BuyerProfilePieChart() {


	const [aggregate,SetAggregate]=useState([])

	//  data = [
	// 	{ name: "EPMLOYEE", value: 3},
	// 	{ name:  "CUSTOMER", value: 4 },
	// 	{ name: "GUEST", value: 1 }
	// ]

	

	useEffect(() => {
		// Function to fetch data from the API
		const fetchDataFromAPI = async () => {
		  try {
			const response = await axiosAPI.get(
			  `/aggregates/transactions-users?status=COMPLETED`,{
				headers: {
				  Authorization: `Bearer ${localStorage.getItem('authToken')}` 
				}
			  }
			) ; 
			console.log(response);// Replace with your API endpoint 
			// if (!response.ok) {
			//     throw new Error(`HTTP error! Status: ${response.status}`); 
			//   }
			const result = response.data;
			console.log("result,",result);
			//console.log("this is the first role",result.data.aggregate[0]._id)
			SetAggregate(result.data.aggregate.map((item)=>{return {name:item._id,value:item.count}}));
			//console.log("this is also the first role",aggregate[0]._id)
			
			
			// debugger
		  } catch (error) {
			// debugger
			console.error('Error fetching data:', error);
		  }
		};
	
		// Call the fetchData function 
		fetchDataFromAPI();
	  }, []);

	//   let data=aggregate

	  const data = [
		// { name: aggregate[0]._id, value: aggregate[0].count},
		// { name:  aggregate[1]._id, value: aggregate[1].count },
		// { name: aggregate[2]._id, value: aggregate[2].count }
	]

	return (
		<div className="w-[20rem] h-[22rem] bg-white p-4 rounded-sm border border-gray-200 flex flex-col">
			<strong className="text-gray-700 font-medium">Buyer Profile</strong>
			<div className="mt-3 w-full flex-1 text-xs">
				<ResponsiveContainer width="100%" height="100%">
					<PieChart width={400} height={300}>
						<Pie
							data={aggregate}
							cx="50%"
							cy="45%"
							labelLine={false}
							label={renderCustomizedLabel}
							outerRadius={105}
							fill="#8884d8"
							dataKey="value"
						>
							{aggregate.map((_, index) => (
								<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
							))}
						</Pie>
						<Legend />
					</PieChart>
				</ResponsiveContainer>
			</div>
		</div>
	)
}