
import React, { useState ,useEffect} from 'react';
import {
    MaterialReactTable,
    createMRTColumnHelper,
    useMaterialReactTable,
  } from 'material-react-table';
  import { Box, Button,Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
  import FileDownloadIcon from '@mui/icons-material/FileDownload';
  import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here

  import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Ensure to import the styles
import 'react-date-range/dist/theme/default.css'; 
import { axiosAPI } from '../API/api'; // Import API function
import { format } from 'date-fns'; 
  
  const columnHelper = createMRTColumnHelper();
  
  const columns = [
    columnHelper.accessor('id', {
      header: 'Name',
      size: 40,
    }),
    columnHelper.accessor('product', {     
      header: 'Orders',
      size: 140,
    }),
    columnHelper.accessor('beansUsed', {     
      header: 'With beans',
      size: 140,
    }),
    columnHelper.accessor('role', {
      header: 'role',
      size: 120,
    }),
    columnHelper.accessor('type', {
      header: ' type',
      size: 120,
    }),
    columnHelper.accessor('totalPrice', {
      header: 'Total Price',
      size: 80,
    }),
    
    columnHelper.accessor('transactionType', {
      header: 'Paid with',
      size: 220,
    }),
    columnHelper.accessor('createdAt', {
      header: 'Date',
    }),
  ];
  
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });
  
  // let date1 , date2;

  
  const Example = () => {

      //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(true);

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10, //customize the default page size
  });
  
  useEffect(() => {
    console.log("the pagination state changed!")
    console.log(pagination.pageIndex)
    console.log(pagination.pageSize)
    setCurrentPage(pagination.pageIndex + 1)
   
  }, [pagination.pageIndex, pagination.pageSize]);

    
    const [data, setData] = useState([]);

    useEffect(() => {
    
      // Function to fetch data from the API
      const fetchDataFromAPI = async () => {
        try {
          const token = (JSON.parse(localStorage.getItem('user')))
          const response = await axiosAPI.get(
            `/transactions/bulk?status=COMPLETED&role=ALL&page=${currentPage}`,{
              headers: {
                Authorization: `Bearer ${token.data.accessToken}` 
              }
            }
          ) ;  
          console.log("this is the response",response);// Replace with your API endpoint
         
          const result = response.data;
          console.log(result);
          console.log("this is the docs",result.data.transaction.docs);
          const fullData = []

          result.data.transaction.docs.map((obj) => {
            const newData = {};

            newData.id = obj.userId.firstName + " " + (obj.userId.lastName || '');
            newData.type = obj.customerType.type;
            newData.role = obj.customerType.role;
            newData.totalPrice = Math.round(obj.totalPrice); // Rounded total price
            newData.createdAt = format(new Date(obj.createdAt), 'dd MMM yyyy'); // Format the date
            newData.transactionType =obj.transactionType;
            // let =""
            let beansUsed="",product = '';
            obj.orders.map((order) => {
              product += order.product.name + ' x ' + order.quantity + '. '
             if( order.beansUsed > 0) 
              { (beansUsed += order.product.name + ' x ' + order.beansUsed + '. ')}
             else{
              beansUsed.length===0 && (beansUsed ="No beans used")
             }
          })
            newData.product = product;

        
            newData.beansUsed = beansUsed;

            return fullData.push(newData)
            
          })

          setData(fullData)
          setTotalPages(result.data.transaction.totalDocs);
          setHasMorePages(result.data.transaction.hasNextPage);

          console.log("this is the data ",data)
          
        } catch (error) {
          
          
          console.error('Error fetching data:', error);
        }
      };
  
     
      fetchDataFromAPI();
    },[currentPage]);

   
    
    const [dateRange, setDateRange] = useState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
  
    const [isDateRangeDialogOpen, setIsDateRangeDialogOpen] = useState(false);

    

    const handleExportRows = (rows) => {

      const rowData = rows.map((row) => row.original);
      console.log(rowData)
    
      const csv = generateCsv(csvConfig)(rowData);
      download(csvConfig)(csv);
    };
  
    const handleExportData = () => {

      if(data.length===0){
        alert ("There are no rows selected please select rows!")
              } else {
      const csv = generateCsv(csvConfig)(data);
      download(csvConfig)(csv);
     } };

    const handleDateRangeDialogOpen = () => {
      setIsDateRangeDialogOpen(true);
    };
  
    const handleDateRangeDialogClose = () => {
      setIsDateRangeDialogOpen(false);
    };
  
    const handleDateRangeSelect = async (ranges) => {
      let selectedData = []
      let newSelectedData = []
      console.log("ranges is",ranges)
      console.log("data is",data)
   


  const endDate = ranges[0].endDate.setUTCHours(23,59,59,999);
  const endDateFinal= Date.parse(new Date(new Date(endDate).setDate(new Date(endDate).getDate()+1)).toUTCString())

  const token = (JSON.parse(localStorage.getItem('user')))
  const response = await axiosAPI.post(
    `/transactions/range`,{
      start: ranges[0].startDate,
      end: endDateFinal
    },{
      headers: {
        Authorization: `Bearer ${token.data.accessToken}` 
      }
    }
  ) ;
  console.log("This is the response from the range api",response);
  console.log("This is the response from the data",data);
  response.data.data.transaction.map((transaction)=>{
    let beansUsed="",product = '';
            transaction.orders.map((order) => {
              product += order.product.name + ' x ' + order.quantity + '. '
             if( order.beansUsed > 0) 
              { (beansUsed += order.product.name + ' x ' + order.beansUsed + '. ')}
             else{
              beansUsed.length===0 && (beansUsed ="No beans used")
             }
          })
    const newData={
      name: `${transaction.userId.firstName} ${transaction.userId.lastName || ''}`,
      type: transaction.customerType.type,
      role: transaction.customerType.role,
      totalPrice: transaction.totalPrice,
      createdAt: transaction.createdAt,
      transactionType: transaction.transactionType,
      product,
      beansUsed
    }
    newSelectedData.push(newData)
  })
 
      data.map((item) => {
        const date = Date.parse(item.createdAt) 
       

        
        console.log("date",date)
        
        console.log("created at is ",Date.parse(item.createdAt))
       
        console.log("start date",Date.parse(ranges[0].startDate))
        console.log("end date is",Date.parse(new Date(new Date(endDate).setDate(new Date(endDate).getDate()+1)).toUTCString()))
     
        if (Date.parse(ranges[0].startDate) <= date  && date <= endDateFinal) {
          selectedData.push(item)
         
        }  
      })

      if(selectedData.length===0){
            alert ("There are no transactions on the date you chose!")
      }
      else{
        console.log(selectedData)
        const csv = generateCsv(csvConfig)(newSelectedData);
        download(csvConfig)(csv);
        handleDateRangeDialogClose();
      }
    };
  
    const table = useMaterialReactTable({
      manualPagination :true,
      rowCount:totalPages,
      state:{pagination},
      onPaginationChange: setPagination,
      columns,
      data,
      enableRowSelection: true,
      columnFilterDisplayMode: 'popover',
      paginationDisplayMode: 'pages',
      positionToolbarAlertBanner: 'bottom',
      renderTopToolbarCustomActions: ({ table }) => (
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            padding: '8px',
            flexWrap: 'wrap',
          }}
        >
          <Button
           
            onClick={handleExportData}
            startIcon={<FileDownloadIcon />}
          >
            Export All Data
          </Button>
          <Button
            disabled={table.getPrePaginationRowModel().rows.length === 0}
            //export all rows, including from the next page, (still respects filtering and sorting)
            onClick={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            }
            startIcon={<FileDownloadIcon />}
          >
            Export All Rows
          </Button>
          <Button
            disabled={table.getRowModel().rows.length === 0}
            //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
            onClick={() => handleExportRows(table.getRowModel().rows)}
            startIcon={<FileDownloadIcon />}
          >
            Export Page Rows
          </Button>
          <Button
            disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
            //only export selected rows
            onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
            startIcon={<FileDownloadIcon />}
          >
            Export Selected Rows
          </Button>
          <Button onClick={handleDateRangeDialogOpen} variant="outlined">
          Date Range
        </Button>
        </Box>
      ),
    });
  
    return (
      <>
        <MaterialReactTable table={table}
         />
        <Dialog open={isDateRangeDialogOpen} onClose={handleDateRangeDialogClose}>
          <DialogTitle>Date Range</DialogTitle>
          <DialogContent>
            <DateRangePicker
              ranges={dateRange}
              onChange={(ranges) => setDateRange([ranges.selection||ranges])}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDateRangeSelect(dateRange)}>Apply</Button>
            <Button onClick={handleDateRangeDialogClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  
  export default Example;